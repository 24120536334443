<template>
  <Swiper
    ref="swiper"
    :modules="modules"
    :slides-per-view="1.1"
    :space-between="16"
    :speed="600"
    :parallax="true"
    :grabCursor="true"
    :breakpoints="{
      640: {
        slidesPerView: 1.1
      },
      1024: {
        spaceBetween: 40,
        slidesPerView: 1.1
      }
    }"
    class="position-swiper relative m-0 mx-auto h-auto w-full p-0"
    @reach-end="isEnd = true"
    @reach-beginning="isEnd = false"
    @slide-change="onSlideChange"
  >
    <SwiperSlide v-for="(position, idx) in data" :key="idx" class="max-w-[640px] lg:max-w-none">
      <div class="flex !h-full flex-col gap-1 lg:flex-row">
        <Image
          class="aspect-3/2 rounded-3xl object-cover lg:aspect-square"
          loading="lazy"
          :src="
            position?.image!.responsiveImage
              ? position?.image!.responsiveImage?.src
              : position?.image!.url
          "
          :srcset="
            position?.image!.responsiveImage
              ? position?.image!.responsiveImage?.webpSrcSet
              : undefined
          "
          :width="
            position?.image!.responsiveImage
              ? position?.image!.responsiveImage?.width
              : position?.image!.width
          "
          :height="
            position?.image!.responsiveImage
              ? position?.image!.responsiveImage?.height
              : position?.image!.height
          "
          :sizes="position.image?.responsiveImage?.sizes"
          :alt="position?.image!.alt!"
        />
        <div
          class="flex h-full flex-col justify-between rounded-3xl p-6 lg:justify-between xl:px-12 xl:py-10"
          :class="idx % 2 === 0 ? 'bg-blue-100' : 'bg-light-gray-600'"
        >
          <Heading semantic class="mb-3 text-2xl text-blue xl:text-6xl">
            {{ position.heading }}
          </Heading>
          <div class="text-base text-neutral-600 xl:text-2xl">
            {{ position.descriptionText }}
          </div>
        </div>
      </div>
    </SwiperSlide>
    <PositionsSwiperNav
      class="absolute top-1/2 z-10 hidden -translate-y-1/2 lg:right-4 lg:block xl:right-8"
      :is-end="isEnd"
      :rotation="rotation"
    />
  </Swiper>
</template>

<script setup lang="ts">
import { Parallax, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { PositionRecord } from '~/types/generated'

import 'swiper/css'

defineProps({
  data: {
    type: Array as PropType<PositionRecord[]>,
    default: () => []
  }
})

const swiper = ref<HTMLElement | null>(null)

const modules = [Parallax, A11y]
const isEnd = ref(false)
const rotation = ref(0)

function onSlideChange(swiper: typeof Swiper) {
  rotation.value =
    swiper.activeIndex < swiper.previousIndex ? rotation.value + 180 : rotation.value - 180
}
</script>

<style lang="postcss">
.position-swiper {
  @apply overflow-visible;

  .swiper-slide {
    @apply !h-auto;
  }
}
</style>
