<template>
  <div>
    <CircleButton
      class="position-swiper-nav transition-transform duration-700"
      :style="{'--rotateDeg': `${rotation}deg`}"
      color="orange"
      :title="isEnd ? $t('swiper.previous') : $t('swiper.next')"
      icon-name="swipe"
      icon-color="white"
      icon-size="2xl"
      size="lg"
      @click="isEnd ? swiper.slidePrev() : swiper.slideNext()"
    />
  </div>
</template>

<script setup lang="ts">
import { useSwiper } from 'swiper/vue'

defineProps({
  isEnd: {
    type: Boolean,
    required: true
  },
  rotation: {
    type: Number,
    required: true
  }
})

const swiper = useSwiper()
</script>

<style lang="postcss" scoped>
.position-swiper-nav {
  @apply rotate-[var(--rotateDeg)];
}
</style>
